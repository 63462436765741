import React from 'react';
import Dashboard from './pages/Dashboard';
import { Routes, Route } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import LoginRegister from './pages/LoginRegister';
import VerifyEmail from './pages/VerifyEmail';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1a237e', // Dark navy blue
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
  },
});

function App() {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<LoginRegister />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
