import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const [verificationStatus, setVerificationStatus] = useState('Verifying...');
  const query = new URLSearchParams(useLocation().search);
  const token = query.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      if (!token) {
        setVerificationStatus('Invalid verification link');
        return;
      }

      try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/verify-email`, { token }, {
          withCredentials: true,
        });
        console.log('Email verification response:', response.data);
        setVerificationStatus('Email verified successfully!');
        setTimeout(() => navigate('/login'), 3000); // Redirect to login page after 3 seconds
      } catch (error) {
        console.error(error);
        setVerificationStatus('Email verification failed. Please try again.');
      }
    };

    verifyEmail();
  }, [token, navigate]);

  return (
    <div>
      <h2>Email Verification</h2>
      <p>{verificationStatus}</p>
    </div>
  );
};

export default VerifyEmail;