import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TextField, Button, Typography, Container, Box, createTheme, ThemeProvider } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Create a dark theme
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1a237e', // Dark navy blue
    },
    background: {
      default: '#303030',
      paper: '#424242',
    },
  },
});

const LoginRegister = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [clientName, setClientName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const apiUrl = process.env.REACT_APP_API_URL || 'https://ptassistant.io:3000';
    try {
      if (isLogin) {
        const response = await axios.post(`${apiUrl}/api/login`, { email, password }, { withCredentials: true });
        console.log('Login response:', response.data);
        alert('Login successful!');
        navigate('/dashboard');
      } else {
        const response = await axios.post(`${apiUrl}/api/register`, { clientName, email, password }, { withCredentials: true });
        console.log('Registration response:', response.data);
        alert('Registration successful! Please check your email to verify your account.');
      }
    } catch (error) {
      console.error(error);
      alert(isLogin ? 'Login failed.' : 'Registration failed.');
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
            boxShadow: 3,
          }}
        >
          {/* Placeholder Logo */}
          <Box
            component="img"
            sx={{
              height: 64,
              mb: 2,
              bgcolor: 'white',
              p: 1,
              borderRadius: 1,
            }}
            alt="Your logo"
            src="https://via.placeholder.com/150x64?text=Your+Logo"
          />
          <Box sx={{ width: '100%', mb: 4, position: 'relative', height: 40, borderRadius: 20, overflow: 'hidden' }}>
            <motion.div
              initial={false}
              animate={{ x: isLogin ? '0%' : '100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                background: '#1a237e', // Dark navy blue
                borderRadius: 20,
                width: '50%',
              }}
            />
            <Button
              onClick={() => setIsLogin(true)}
              sx={{
                width: '50%',
                height: '100%',
                zIndex: 1,
                position: 'absolute',
                left: 0,
                color: isLogin ? 'white' : 'inherit',
              }}
            >
              Login
            </Button>
            <Button
              onClick={() => setIsLogin(false)}
              sx={{
                width: '50%',
                height: '100%',
                zIndex: 1,
                position: 'absolute',
                right: 0,
                color: !isLogin ? 'white' : 'inherit',
              }}
            >
              Register
            </Button>
          </Box>
          <AnimatePresence mode="wait">
            <motion.div
              key={isLogin ? 'login' : 'register'}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <form onSubmit={handleSubmit}>
                {!isLogin && (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Client Name"
                    name="name"
                    autoComplete="name"
                    autoFocus
                    value={clientName}
                    onChange={(e) => setClientName(e.target.value)}
                  />
                )}
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus={isLogin}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2, bgcolor: '#1a237e', color: 'white', '&:hover': { bgcolor: '#303f9f' } }}
                  endIcon={<ArrowForward />}
                >
                  {isLogin ? 'Sign In' : 'Sign Up'}
                </Button>
              </form>
            </motion.div>
          </AnimatePresence>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LoginRegister;
